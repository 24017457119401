import {Country} from "../../region/types/Country";
import {RegionType} from "../../region/types/RegionType";

interface IRegion {
    type: RegionType;
    name: string;
    short_name: string;
    is_duplicate: boolean;
    parent: {
        name: string;
    } | null;
    country?: number | null;
}

export const generateMetaRegionName = (region: IRegion, isInDescription?: boolean) => {
    const districtString = region.country && region.country !== Country.POLAND ? "" : "powiat ";

    switch (region.type) {
        case RegionType.NEIGHBORHOOD:
            if (region.short_name) {
                const regionNameArray = region.short_name.split(",");
                return `${regionNameArray[0]},${regionNameArray[regionNameArray.length - 1]}`;
            }
            return region.name;
        case RegionType.DISTRICT:
        case RegionType.AGGREGATION:
            return region.short_name; // "Warszawa, Wola", "Warszawa, Wola, Mirów" "Warszawa i okolice"
        case RegionType.COUNTY:
            if (isInDescription) {
                return `${districtString}${region.name}${region.parent && region.is_duplicate ? `, województwo ${region.parent.name}` : ""}`; // "powiat piaseczyński", "powiat bielski, województwo śląskie"
            }
            return `${districtString}${region.name}${region.parent && region.is_duplicate ? `, ${region.parent.name}` : ""}`; // "powiat piaseczyński", "powiat bielski, śląskie"
        case RegionType.TOWN:
            return `${region.name}${region.parent ? `, ${districtString}${region.parent.name}` : ""}`; // "Józefosław, powiat piaseczyński"
        case RegionType.CITY:
            return `${region.name}${region.parent && region.is_duplicate ? `, ${districtString}${region.parent.name}` : ""}`; // "Warszawa", "Józefów, powiat otwocki"
        case RegionType.VOIVODESHIP:
        default:
            return region.name; // "mazowieckie", "Warszawa"
    }
};
import {Country} from "../../region/types/Country";
import {RegionType} from "../../region/types/RegionType";

interface IRegion {
    type: RegionType;
    name: string;
    short_name: string;
    is_duplicate: boolean;
    parent: {
        name: string;
    } | null;
    country?: number | null;
}

export const generateMetaRegionName = (region: IRegion, isInDescription?: boolean) => {
    const districtString = region.country && region.country !== Country.POLAND ? "" : "powiat ";

    switch (region.type) {
        case RegionType.NEIGHBORHOOD:
            if (region.short_name) {
                const regionNameArray = region.short_name.split(",");
                return `${regionNameArray[0]},${regionNameArray[regionNameArray.length - 1]}`;
            }
            return region.name;
        case RegionType.DISTRICT:
        case RegionType.AGGREGATION:
            return region.short_name; // "Warszawa, Wola", "Warszawa, Wola, Mirów" "Warszawa i okolice"
        case RegionType.COUNTY:
            if (isInDescription) {
                return `${districtString}${region.name}${region.parent && region.is_duplicate ? `, województwo ${region.parent.name}` : ""}`; // "powiat piaseczyński", "powiat bielski, województwo śląskie"
            }
            return `${districtString}${region.name}${region.parent && region.is_duplicate ? `, ${region.parent.name}` : ""}`; // "powiat piaseczyński", "powiat bielski, śląskie"
        case RegionType.TOWN:
            return `${region.name}${region.parent ? `, ${districtString}${region.parent.name}` : ""}`; // "Józefosław, powiat piaseczyński"
        case RegionType.CITY:
            return `${region.name}${region.parent && region.is_duplicate ? `, ${districtString}${region.parent.name}` : ""}`; // "Warszawa", "Józefów, powiat otwocki"
        case RegionType.VOIVODESHIP:
        default:
            return region.name; // "mazowieckie", "Warszawa"
    }
};
